const Logger = require('./Logger');
const ErrorDefinitions = require('./ErrorDefinitions');

class ExtendableError extends Error {
  constructor(errorDefinition = {}, payload = {}) {
    super(`pwjs: ${errorDefinition.message}`);
    this.type = errorDefinition.type;
    this.code = errorDefinition.code;
    this.name = this.constructor.name;
    this.payload = payload;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = (new Error(errorDefinition.message)).stack;
    }
    Logger.error(errorDefinition.message);
    Logger.debugTable(this);
  }
}

const ConnectionError = class ConnectionError extends ExtendableError {
  constructor(...args) {
    super(...args);
  }
  static definitions = ErrorDefinitions.ConnectionErrorDefinitions;
};
const RequestError = class RequestError extends ExtendableError {
  constructor(...args) {
    super(...args);
    const [, , , message] = args;
    this.response = message;
  }
  static definitions = ErrorDefinitions.RequestErrorDefinitions;
};

module.exports = Object.freeze({
  ConnectionError,
  RequestError,
});
