const { ConnectionError } = require('./Errors');
const Utils = require('@igp/shared').Utils;

const MESSAGE_TIMEOUT = 30000;

module.exports = class PendingMessages {

  constructor() {
    this._timeoutIds = {};
    this._linkedPendingMessages = {};
    this._pendingMessages = new Proxy({}, {
      set: (obj, prop, value) => {
        const timeoutId = setTimeout(() => {
          this.reject(prop, new ConnectionError(ConnectionError.definitions.REQUEST_TIMEOUT));
        }, value.customResponseTimeoutInMilliSeconds || MESSAGE_TIMEOUT);

        const props = prop.split(',');
        if (props.length > 1) {
          props.forEach((p) => {
            Reflect.set(obj, p, value);
            Reflect.set(this._timeoutIds, p, timeoutId);
            Reflect.set(this._linkedPendingMessages, p, prop);
          });
          return true;
        }

        Reflect.set(obj, prop, value);
        Reflect.set(this._timeoutIds, prop, timeoutId);
        return true;
      },
    });
  }

  add(prop, val) {
    Reflect.set(this._pendingMessages, prop, val);
  }

  has(prop) {
    return Reflect.has(this._pendingMessages, prop);
  }

  get(prop) {
    return Reflect.get(this._pendingMessages, prop);
  }

  delete(prop) {
    clearTimeout(this._timeoutIds[prop]);

    if (Utils.isString(this._linkedPendingMessages[prop])) {
      this._linkedPendingMessages[prop].split(',').forEach((p) => {
        Reflect.deleteProperty(this._timeoutIds, p);
        Reflect.deleteProperty(this._pendingMessages, p);
      });
      return true;
    }

    Reflect.deleteProperty(this._timeoutIds, prop);
    Reflect.deleteProperty(this._pendingMessages, prop);
    return true;
  }

  resolve(prop, resolveValue) {
    if (this.has(prop)) {
      const pendingMessage = this.get(prop);
      resolveValue && (resolveValue.__pwjsInitiatorInstance__ = pendingMessage.pwjsInitiatorInstance);
      Utils.isFunction(pendingMessage.resolve) && pendingMessage.resolve(resolveValue);
      this.delete(prop);
    }
  }

  reject(prop, rejectValue) {
    if (this.has(prop)) {
      const pendingMessage = this.get(prop);
      Utils.isFunction(pendingMessage.reject) && pendingMessage.reject(rejectValue);
      this.delete(prop);
    }
  }

  rejectAll(rejectValue = null) {
    Reflect.ownKeys(this._pendingMessages).forEach((prop) => {
      this.reject(prop, rejectValue);
    });
  }
}
