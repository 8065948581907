module.exports = superclass => class extends superclass {

  constructor(initiator) {
    super(initiator);
  }

  response(message) {
    return new Promise((resolve, reject) => {
      store.state.languageCode = message.data.languageCode;
      super.response(message).then(resolve);
    });
  }
}
