const root = require('./Root');
const EventEmitter = superclass => class extends superclass {

    emit(eventName) {
        let listeners = this._listeners;
        if (listeners && listeners[eventName]) {
            const fParams = [].slice.call(arguments, 1);
            listeners[eventName].forEach((f) => {
                f.apply(root, fParams);
            });
        }
    }

    on(eventName, f) {
        let listeners = this._listeners;
        listeners || (listeners = this._listeners = {});
        listeners[eventName] || (listeners[eventName] = []);
        listeners[eventName].push(f);
    }

    once(eventName, f) {
        var self = this;
        self.on(eventName, function g() {
            self.off(eventName, g);
            f.apply(this, arguments);
        });
    }

    off(eventName, f) {
        let listeners = this._listeners;
        if (listeners && listeners[eventName]) {
            listeners[eventName] = listeners[eventName].filter((x) => x !== f);
        }
    }
}

module.exports = EventEmitter;
