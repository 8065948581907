const Utils = require('@igp/shared').Utils;
const store = require('../../../lib/StoreData');

module.exports = superclass => class extends superclass {

  constructor(initiator) {
    super(initiator);
  }

  response(message) {
    return new Promise((resolve, reject) => {
      Utils.setLocalStorage('sessionId', message.data.sessionId);
      store.state.loggedIn = true;
      super.response(message).then(resolve);
    });
  }
}
