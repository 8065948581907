const Utils = require('@igp/shared').Utils;

module.exports = superclass => class extends superclass {

  constructor() {
    super();
    this.customHeaders = {
      'content-type': 'multipart/form-data',
    };
  }
  
  request(url, options) {
    const body = options.body;

    if (!(body instanceof FormData)) {
      const fd = new FormData();
      for (const [key, value] of Object.entries(body)) {
        if (Utils.isArray(value)) {
          value.forEach((v) => {
            fd.append(`${key}[]`, v);
          })
        } else {
          fd.append(key, value);
        }
      }
      options.body = fd;
    }

    return super.request(url, options);
  }
}