const Utils = require('@igp/shared').Utils;
const store = require('../../../lib/StoreData');

module.exports = superclass => class extends superclass {

  constructor(initiator) {
    super(initiator);
    this.allowedWithoutAuthentication = true;
    this.emitMessageOnInitiatorInstance = true;

    const packageName = store.state.package.name;
    const packageVersion = store.state.package.version;
    this.softwareSerial =
      packageName.substring(packageName.indexOf('/') + 1) +
      '_v' +
      packageVersion;
  }

  request() {
    const deviceId = Utils.getLocalStorage('deviceId');
    const clientType = store.state.initOptions.clientType;

    const data = {
      cageShortName: store.state.initOptions.cage,
      languageCode: store.state.languageCode,
      softwareSerial: this.softwareSerial,
      userAgent: typeof navigator !== 'undefined' ? navigator.userAgent : '',
      loginSite: store.state.initOptions.loginSite,
      sessionId: Utils.getLocalStorage('sessionId') || '',
    }
    deviceId && (data.deviceId = deviceId);
    clientType && (data.clientType = clientType);

    return super.request(data);
  }

  response(message) {
    return new Promise((resolve, reject) => {
      Utils.setLocalStorage('sessionId', message.data.sessionId);
      super.response(message).then(resolve);
    });
  }

}
