const Utils = require('@igp/shared').Utils;
const store = require('./StoreData');

const DEFAULT_OPTIONS = {
  url: {
    required: true,
    defaultValue: null,
  },

  urlHttp: {
    required: true,
    defaultValue: null,
    modifier: (v) => Utils.isNonEmptyString(v) && v.replace(/\/?$/, '/'), // add trailing slash if missing
  },

  cage: {
    required: true,
    defaultValue: null,
  },

  clientType: {
    required: false,
    defaultValue: '',
  },

  languageCode: {
    required: false,
    defaultValue: 'ENG',
    cb: (v) => Utils.isNonEmptyString(v) && (store.state.languageCode = v),
  },

  loginSite: {
    required: false,
    defaultValue: (typeof window !== 'undefined') ? window.location.origin : '',
  },

  sessionId: {
    required: false,
    defaultValue: null,
    cb: (v) => Utils.isNonEmptyString(v) && (Utils.setLocalStorage('sessionId', v)),
  },

  deviceId: {
    required: false,
    defaultValue: null,
    cb: (v) => Utils.isNonEmptyString(v) && (Utils.setLocalStorage('deviceId', v)),
  },

  logLevel: {
    required: false,
    defaultValue: 'error',
    cb: (v) => store.setLogLevel(v),
  },
}

const setApiOptions = function (initOptions, defaultOptions = DEFAULT_OPTIONS, checkRequired = true) {
  let options = {};

  Object.keys(defaultOptions).forEach(option => {
    const initOptionValue = initOptions[option];
    const optionDefinition = defaultOptions[option];
    const defaultOptionValue = optionDefinition.defaultValue;
    const defaultOptionRequired = optionDefinition.required;

    if (
      Utils.isUndefined(initOptionValue) &&
      defaultOptionRequired &&
      checkRequired
    ) {
      throw new Error(`Error: pwjs init option '${option}' is missing.`);
    }

    const isInitOptionValueObject = Utils.isObject(initOptionValue);
    const isDefaultOptionValueObject = Utils.isObject(defaultOptionValue);

    if (isInitOptionValueObject && isDefaultOptionValueObject) {
      options[option] = setApiOptions(Object.assign({}, initOptionValue), defaultOptionValue);
    } else if (!Utils.isUndefined(initOptionValue)) {
      options[option] = Utils.isFunction(optionDefinition.modifier)
        ? optionDefinition.modifier(initOptionValue)
        : initOptionValue;
    } else if (isDefaultOptionValueObject) {
      options[option] = setApiOptions({}, defaultOptionValue, false);
    } else {
      options[option] = defaultOptionValue;
    }

    if (Utils.isFunction(optionDefinition.cb)) {
      optionDefinition.cb(options[option]);
    }
  });

  return options;
}

exports.setApiOptions = setApiOptions;
