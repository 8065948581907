const store = require('../../../lib/StoreData');

module.exports = superclass => class extends superclass {

  constructor(initiator) {
    super(initiator);
  }

  response(message) {
    return new Promise((resolve, reject) => {
      store.setStateBalance(message.data);
      super.response(message).then(resolve);
    });
  }

}
