module.exports = superclass => class extends superclass {

  constructor(initiator) {
    super(initiator);
    this.skipPendingMessage = true;
  }

  request() {
    this.sendMessage();
  }

  response() {
    this.request();
  }

}
