const store = require('../../../lib/StoreData');

module.exports = superclass => class extends superclass {

  constructor(initiator) {
    super(initiator);
  }

  request(data) {
    if (!data.languageCode) {
      data.languageCode = store.state.languageCode;
    }
    return super.request(data);
  }
}
