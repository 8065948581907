const WebSocket = require('isomorphic-ws');
const PendingMessages = require('./PendingMessages');
const SocketEventCodes = require('./SocketEventCodes');
const { Utils, EventEmitter } = require('@igp/shared');
const getActionClassInstance = require('./mixins/actions/Action').getActionClassInstance;
const { ConnectionError, RequestError } = require('./Errors');
const Logger = require('./Logger');

function createPendingMessageId(message, alternativeType) {
  return alternativeType
  ? [`${message.type}:${message.cId}`, `${alternativeType}:${message.cId}`]
  : `${message.type}:${message.cId}`;
}

function resetSocketConnectionRetryTimeoutIdex() {
  socketConnectionRetryTimeout.currentRetryIndex = 0;
}

let socketConnectionRetryTimeoutId = null;
const socketConnectionRetryTimeout = new Proxy ({
  currentRetryIndex: 0,
  retryTimeouts: [100, 1000, 1000, 2000, 3000, 7000, 10000, 17000, 27000],
 }, {
  get: (target, prop) => {
    let ret = target[prop];
    if (prop === 'value') {
      ret = target.retryTimeouts[target.currentRetryIndex];
      if (++target.currentRetryIndex >= target.retryTimeouts.length) {
        resetSocketConnectionRetryTimeoutIdex();
      }
      clearTimeout(socketConnectionRetryTimeoutId);
      socketConnectionRetryTimeoutId = setTimeout(() => {
        resetSocketConnectionRetryTimeoutIdex;
      },
        2 * target.retryTimeouts[target.retryTimeouts.length - 1]
      );
    }
    return ret;
  }
});

module.exports = class Socket extends EventEmitter(class Base {}) {
  constructor(url, pwjs) {
    super();

    this.autoReconnect = true;
    this.socketConnectionTimeoutId = null;

    this.url = url;
    this.pwjs = pwjs;
    this.socket = null;
    this.preventConnect = false;
    this.pendingMessages = new PendingMessages();
    this.cId = new Proxy({value: 0}, {
      get: (target, prop) => {
        if (prop === 'value') {
          target.value++;
          return target[prop].toString();
        }
        return target[prop];
      }
    });

    this.handleSocketOpen = this.handleSocketOpen.bind(this);
    this.handleSocketClose = this.handleSocketClose.bind(this);
    this.handleSocketError = this.handleSocketError.bind(this);
    this.handleSocketMessage = this.handleSocketMessage.bind(this);
    this.handleNetworkOnline = this.handleNetworkOnline.bind(this);
    this.handleNetworkOffline = this.handleNetworkOffline.bind(this);
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);

    this.attachNetworkEventListeners();
    this.attachBeforeUnloadEventListener();
  }

  connect(options = {}) {
    if (this.socket || this.preventConnect) { return; }

    if (Utils.isBoolean(options.autoReconnect)) {
      this.autoReconnect = options.autoReconnect;
    }
    this.socket = new WebSocket(this.url);
    this.detachSocketEventListeners();
    this.attachSocketEventListeners();
    Logger.debug('Websocket created', this.url);
  }

  close(code) {
    this.socket && this.socket.close(code);
  }

  handleSocketOpen(event) {
    this.emit('open', event);
    Logger.debug('Websocket opened');
  }

  handleSocketClose(event) {
    this.cancelScheduledReconnectAttempt();
    this.detachSocketEventListeners();
    this.pendingMessages.rejectAll(new Error('Socket connection closed'));
    this.socket = null;
    this.emit('close', event);
    Logger.debug('Websocket closed');

    this.shouldAutoReconnect(event)
      ? this.scheduledReconnectAttempt()
      : resetSocketConnectionRetryTimeoutIdex();
  }

  handleSocketError(event) {
    this.emit('error', event);
    Logger.error('Websocket error', event);
  }

  handleSocketMessage(event) {
    let message = null;
    try { message = JSON.parse(event.data); } catch (e) { }

    if (!message) {
      return Logger.error('Received message is not in JSON format.', message);
    }

    Logger.logReceivedWebsocketMessage(message);

    if (message.cId) {
      const pendingMessageId = createPendingMessageId(message);
      if (this.pendingMessages.has(pendingMessageId)) {
        return message.error
          ? this.pendingMessages.reject(pendingMessageId, new RequestError(
            RequestError.definitions.BAD_REQUEST,
            message.error,
            message
          ))
          : this.pendingMessages.resolve(pendingMessageId, message);
      }
    }

    const actionClassInstance = getActionClassInstance(message.type, this);
    actionClassInstance.response(message);
  }

  handleNetworkOnline() {
    Logger.info('connection online detected');
    this.connect();
  }

  handleNetworkOffline(event) {
    Logger.info('connection offline detected');
    this.pwjs.send('client-ping').then().catch((error) => {
      Logger.error(error);
      this.handleSocketClose(event);
    });
  }

  handleBeforeUnload() {
    this.preventConnect = true;
  }

  shouldAutoReconnect(event) {
    return this.autoReconnect && event.code !== SocketEventCodes.INTENTIONAL_DISCONNECT;
  }

  scheduledReconnectAttempt() {
    const reconnectTimeout = socketConnectionRetryTimeout.value;
    this.socketConnectionTimeoutId = setTimeout(this.connect.bind(this), reconnectTimeout);
    Logger.debug(`Schedule websocket reconnect attempt in ${reconnectTimeout} ms`);
  }

  cancelScheduledReconnectAttempt() {
    clearTimeout(this.socketConnectionTimeoutId);
    this.socketConnectionTimeoutId = null;
    Logger.debug('Cancel scheduled websocket reconnect attempt');
  }

  sendMessage(message, {
    skipPendingMessage = false,
    pwjsInitiatorInstance = null,
    customResponseTimeoutInMilliSeconds = null,
    alternativeMessageResponseType = null,
  } = {}) {
    return this.socket ?
      new Promise((resolve, reject) => {
        if (skipPendingMessage) {
          this._send(message);
          return Promise.resolve();
        }
        message.cId = this.cId.value;
        this.pendingMessages.add(createPendingMessageId(message, alternativeMessageResponseType), {
          message,
          resolve,
          reject,
          pwjsInitiatorInstance,
          customResponseTimeoutInMilliSeconds,
        });
        this._send(message);
      }) :
      Promise.reject(new ConnectionError(ConnectionError.definitions.NO_WS_CONNECTION));
  }

  _send(message) {
    this.socket.send(JSON.stringify(message));
    Logger.logSentWebsocketMessage(message);
  }

  attachSocketEventListeners() {
    if (!this.socket) { return; }
    this.socket.addEventListener('open', this.handleSocketOpen);
    this.socket.addEventListener('close', this.handleSocketClose);
    this.socket.addEventListener('error', this.handleSocketError);
    this.socket.addEventListener('message', this.handleSocketMessage);
  }

  detachSocketEventListeners() {
    if (!this.socket) { return; }
    this.socket.removeEventListener('open', this.handleSocketOpen);
    this.socket.removeEventListener('close', this.handleSocketClose);
    this.socket.removeEventListener('error', this.handleSocketError);
    this.socket.removeEventListener('message', this.handleSocketMessage);
  }

  attachNetworkEventListeners() {
    if (typeof window !== 'undefined' && window.addEventListener) {
      window.addEventListener('online', this.handleNetworkOnline);
      window.addEventListener('offline', this.handleNetworkOffline);
    }
  }

  attachBeforeUnloadEventListener() {
    if (typeof window !== 'undefined' && window.addEventListener) {
      window.addEventListener('beforeunload', this.handleBeforeUnload);
    }
  }
}
