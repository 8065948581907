const logLevels = ['off', 'error', 'info', 'debug'];
const Utils = require('@igp/shared').Utils;

function augmentLog(color, ...args) {
  const augmentLogCss = `
  color: ${color};
  font-weight: bold;
  background-color: #242424;
  border: 1px solid ${color};
  border-radius: 3px;
  padding: 2px;
  `;

  if (args && args.length > 1 && Utils.isNonEmptyString(args[0]) && args[0].includes('%c')) {
    args[0] = `%cpwjs%c ${args[0]}`;
    args.splice(1, 0, augmentLogCss);
    args.splice(2, 0, '');
  } else {
    args.unshift('');
    args.unshift(augmentLogCss);
    args.unshift('%cpwjs%c');
  }
  args.push(`at ${new Date().toISOString()}`);
  return args;
}

function log(...args) {
  console.log(...augmentLog('orange', ...args));
}

function error(...args) {
  console.log(...augmentLog('red', ...args));
}

function table(...args) {
  console.table(...args);
}

function shouldLog(logLevel) {
  const store = require('./StoreData');
  return logLevels.indexOf(store.state.logLevel) >= logLevels.indexOf(logLevel);
}

module.exports = class {
  static log(...args) {
    log(...args)
  }

  static error(...args) {
    if (shouldLog('error')) {
      error(...args)
    }
  }

  static info(...args) {
    if (shouldLog('info')) {
      log(...args)
    }
  }

  static infoTable(...args) {
    if (shouldLog('info')) {
      table(...args)
    }
  }

  static debug(...args) {
    if (shouldLog('debug')) {
      log(...args)
    }
  }

  static debugTable(...args) {
    if (shouldLog('debug')) {
      table(...args)
    }
  }

  static logSentWebsocketMessage(message) {
    this.info('%c▲ %csend WS message: %c%s%c',
      'color: green; font-weight: bold; font-size: 1.5em; vertical-align: baseline;',
      '',
      'font-weight: bold; text-decoration: underline;',
      message && message.type,
      '',
    );
    this.infoTable(message);
  }

  static logReceivedWebsocketMessage(message) {
    this.info('%c▼ %creceive WS message: %c%s%c',
      'color: red; font-weight: bold; font-size: 1.5em; vertical-align: baseline;',
      '',
      'font-weight: bold; text-decoration: underline;',
      message && message.type,
      '',
    );
    this.infoTable(message);
  }

  static logHTTPRequest(requestOptions) {
    this.info('%c⇡ %cHTTP %c%s%c request: %c%s%c',
      'color: green; font-weight: bold; font-size: 1.5em; vertical-align: baseline;',
      '',
      'font-weight: bold;',
      requestOptions.method.toUpperCase(),
      '',
      'font-weight: bold; text-decoration: underline;',
      requestOptions.url,
      '',
    );
    this.infoTable(requestOptions);
  }

  static logHTTPResponse(responseData) {
    this.info('%c⇣ %cHTTP response: %c%s%c',
      'color: red; font-weight: bold; font-size: 1.5em; vertical-align: baseline;',
      '',
      'font-weight: bold; text-decoration: underline;',
      responseData.url,
      '',
    );
    this.infoTable(responseData);
  }
}

module.exports.logLevels = logLevels;
