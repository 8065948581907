ConnectionErrorDefinitions = {
  REQUEST_TIMEOUT: {
    type: 'REQUEST_TIMEOUT',
    code: 101,
    message: 'Request timeout.'
  },
  NO_WS_CONNECTION: {
    type: 'NO_WS_CONNECTION',
    code: 102,
    message: 'No WebSocket connection.'
  }
};

RequestErrorDefinitions = {
  BAD_REQUEST: {
    type: 'BAD_REQUEST',
    code: 101,
    message: 'Bad request.'
  },
};

module.exports = Object.freeze({
  ConnectionErrorDefinitions,
  RequestErrorDefinitions,
});
