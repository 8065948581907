/*
  For each action you added as a file, you have to
  import it here and define it's api message type
  {
    actionClass: require('./ActionClass'),
    messageType: 'portalws-api-message-type',
  },
*/

module.exports = [
  {
    actionClass: require('./Auth'),
    messageType: 'auth',
  },
  {
    actionClass: require('./Balance'),
    messageType: 'balance',
  },
  {
    actionClass: require('./BalanceDetails'),
    messageType: 'balance-details',
  },
  {
    actionClass: require('./ChangeLanguage'),
    messageType: 'change-language',
  },
  {
    actionClass: require('./ClientPing'),
    messageType: 'client-ping',
  },
  {
    actionClass: require('./CloseAccount'),
    messageType: 'close-account',
  },
  {
    actionClass: require('./ForceLogout'),
    messageType: 'force-logout',
  },
  {
    actionClass: require('./Game'),
    messageType: 'game',
  },
  {
    actionClass: require('./GameHistoryCsv'),
    messageType: 'game-history-csv',
  },
  {
    actionClass: require('./Login'),
    messageType: 'login',
  },
  {
    actionClass: require('./LoginWithToken'),
    messageType: 'login-with-token',
  },
  {
    actionClass: require('./Logout'),
    messageType: 'logout',
  },
  {
    actionClass: require('./Ping'),
    messageType: 'ping',
  },
  {
    actionClass: require('./Player'),
    messageType: 'player',
  },
  {
    actionClass: require('./ProductBlocks'),
    messageType: 'product-blocks',
  },
  {
    actionClass: require('./TransactionHistoryCsv'),
    messageType: 'tx-history-csv',
  },
];
