const store = require('../../../lib/StoreData');

module.exports = superclass => class extends superclass {

  constructor(initiator) {
    super(initiator);
  }

  response(message) {
    return new Promise((resolve, reject) => {
      const balance = { ...message.data };
      balance.balanceVersion = store.state.balance.version;
      balance.balance = message.data.totalBalance;
      store.setStateBalance(balance);
      super.response(message).then(resolve);
    });
  }

}
